<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12 pt-0 pb-2">
          <h1>
            Job # {{ quotation.jobNumber }}
            <span class="float-right">Update</span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 pt-0 pb-0">
          <p>
            <router-link
              custom v-slot="{ navigate }"
              :to="`/live-jobs/overview/${quotation.id}`">
              <v-btn
                small
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                class="relative"
                style="top:-3px">
                  Job Overview
              </v-btn>
            </router-link>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <v-row v-if="quotation.customer === 0">
            <v-col class="col-10 col-sm-6 col-md-4">
              <v-row v-if="foundCustomers.length === 0">
                <v-col class="col-9">
                  <v-text-field
                    label="Search for a customer"
                    v-model="searchTerm"></v-text-field>
                </v-col>
                <v-col class="col-3 pt-8">
                  <v-btn small @click="lookupCustomers">Find customer</v-btn>
                </v-col>
              </v-row>
              <v-combobox v-else v-model="customer" :items="foundCustomers"></v-combobox>
            </v-col>
            <v-col class="col-10 col-sm-6 col-md-4 pt-8">
              <v-btn
                v-if="customer.length > 0"
                small
                @click="populateCustomer">
                Use this customer
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="quotation.customer !== 0">
            <v-col class="col-10 col-sm-6 col-md-4 pt-4">
              <h4>Customer address</h4>
              <span v-if="quotation.customerAddressOne">{{ quotation.customerAddressOne }}</span>
              <span v-if="quotation.customerAddressTwo"><br />{{ quotation.customerAddressTwo }}</span>
              <span v-if="quotation.customerAddressThree"><br />{{ quotation.customerAddressThree }}</span>
              <span v-if="quotation.customerAddressFour"><br />{{ quotation.customerAddressFour }}</span>
              <span v-if="quotation.customerAddressFive"><br />{{ quotation.customerAddressFive }}</span>
              <span v-if="quotation.customerPostcode"><br />{{ quotation.customerPostcode }}</span>
            </v-col>
            <v-col class="col-10 col-sm-6 col-md-4 pt-4">
              <h4 class="pt-4 pb-0">Customer telephone</h4>
              <span v-if="quotation.customerTel">{{ quotation.customerTel }}</span>
            </v-col>
            <v-col class="col-10 col-sm-6 col-md-4 pt-4">
              <v-btn
                class="mt-2 mb-2"
                small
                @click="quotation.customer = 0">
                Change Customer
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-10 col-sm-6 col-md-4 pt-4">
              <v-text-field v-model="quotation.customerReference" label="Customer reference" />
              <v-select
                :items="departments"
                v-model="quotation.department"
                label="Department"
              ></v-select>
              <v-select
                :items="contacts"
                v-model="quotation.contactId"
                item-text="name"
                item-value="id"
                label="Contact"
              ></v-select>
              <template>
                <v-date-picker
                  v-if="!date"
                  v-model="date"
                  show-adjacent-months
                  no-title
                  width="100%"
                ></v-date-picker>
              </template>
              <v-text-field
                v-if="date"
                :value="prettyDate"
                append-icon="mdi-close"
                @click:append="resetDate"
                label="Quotation date">
              </v-text-field>
              <v-select
                v-model="quotation.validFor"
                :items="validities"
                label="Quotation Valid For">
              </v-select>
              <v-select
                v-model="quotation.notice"
                :items="['7 Days', '14 Days']"
                label="Notice required for starting work"
              ></v-select>
              <v-text-field
                v-model="quotation.days"
                label="Days for job"
              ></v-text-field>
              <v-text-field
                v-model="quotation.unsociableHours"
                label="Extra unsociable hours"
              ></v-text-field>
            </v-col>
            
            <v-col class="col-10 col-sm-6 col-md-4">
              <v-text-field v-model="quotation.site" label="Site Address"></v-text-field>
              <v-text-field v-model="quotation.postCode" label="Site Postcode"></v-text-field>
              <app-directions
                v-if="quotation.postCode.length > 3"
                :address="quotation.site"
                :postcode="quotation.postCode"
                @routeFound="populateDistances" />
              <v-text-field v-model="quotation.distance" label="Distance"></v-text-field>
              <v-text-field v-model="quotation.travel_hours" label="Drive Time (Minutes)"></v-text-field>
            </v-col>
            <v-col class="col-10 col-sm-6 col-md-4">
              <v-textarea v-model="quotation.description" label="Job Details"></v-textarea>
              <v-textarea v-model="quotation.notes" label="Notes"></v-textarea>
              <v-text-field v-model="quotation.drawing" label="Drawing Ref"></v-text-field>
              <v-autocomplete
                v-model="quotation.surfaceTypeId"
                :items="surfaceTypes"
                item-text="surface_type"
                item-value="id"
                label="Surface type that materials to be applied to"
              ></v-autocomplete>
              <v-checkbox
                hint="Leave unticked if not required"
                persistent-hint
                v-model="quotation.sectorSeven"
                label="Sector Seven"></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="quotation.customer !== 0">
            <v-col class="col-10 col-sm-6 col-md-4">
              <v-select
                v-model="quotation.domVat0"
                :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                label="Riggots supply to be within the CIS scheme?"
              ></v-select>
            </v-col>
            <v-col class="col-10 col-sm-6 col-md-4">
              <v-select
                v-model="quotation.domVat2"
                :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                label="Is the customer a contractor in the chain?"
              ></v-select>
            </v-col>
            <v-col class="col-10 col-sm-6 col-md-4">
              <v-select
                v-model="quotation.domVat1"
                :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                label="Is the customer VAT registered?"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col
              v-if="changeContact === false" 
              class="col-12 col-md-3 pt-0 pb-2">
              <p>
                <label>Site contact</label><br />
                {{ quotation.siteContact }}<br />
                <v-btn @click="changeContact = true" x-small>Change contact</v-btn>
              </p>
            </v-col>
            <v-col
              v-else
              class="col-12 col-md-3 pt-0 pb-2">
              <v-select
                v-if="addNewContact === false"
                hint="Select a contact, or add a new one"
                persistent-hint
                :items="contacts"
                v-model="quotation.siteContactId"
                item-text="name"
                item-value="id"
                label="Contact"
              ></v-select>
              <v-btn
                v-if="addNewContact === false"
                class="mt-2"
                @click="addNewContact = true"
                x-small>Add New Contact</v-btn>
            </v-col>
            <div class="modal" v-if="addNewContact === true">        
              <v-row>
                <v-col class="col-5 col-sm-3 col-md-2">
                  <v-text-field v-model="newContact.first_name" label="First Name"></v-text-field>
                </v-col>
                <v-col class="col-5 col-sm-3 col-md-2">
                  <v-text-field v-model="newContact.last_name" label="Last Name"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-10 col-sm-6 col-md-4">
                  <v-text-field v-model="newContact.email" label="Email"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-10 col-sm-6 col-md-4">
                  <v-text-field v-model="newContact.tel" label="Tel"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-10 col-sm-6 col-md-4">
                  <v-btn small @click="saveNewContact">Save contact</v-btn>
                  &nbsp;
                  <v-btn small @click="cancelNewContact">Cancel</v-btn>
                </v-col>
              </v-row>
            </div>
            <v-col class="col-12 col-md-3 pt-0 pb-2">
              <v-text-field
                hint="Please add a site telephone number"
                persistent-hint
                v-model="quotation.siteTel"
                label="Site telephone" />
            </v-col>
            <v-col class="col-12 col-md-3 pt-0 pb-2">
              <v-text-field
                hint="Please add a site email"
                persistent-hint
                v-model="quotation.siteEmail"
                label="Site email" />
            </v-col>
            <v-col class="col-12 col-md-3 pt-0 pb-2">
              <label>Special Instructions</label>
              <vue-editor
                id="specialInstructions"
                :editor-toolbar="customToolbar"
                v-model="quotation.specialInstructions" />
            </v-col>
          </v-row>
          <v-row class="pt-10">
            <v-col class="col-12 col-md-3 pt-0 pb-2">
              <v-text-field
                label="Purchase Order Number"
                v-model="quotation.purchase_order"
                hint="Please add a customer PO Number"
                persistent-hint></v-text-field>
            </v-col>
            <v-col class="col-12 col-md-3 pt-0 pb-2">
              <div v-if="quotation.purchase_order_uri.length === 0">
                <v-btn @click="uploadPo = true" small>
                  Upload Purchase Order
                </v-btn>
                <v-dialog max-width="700" v-model="uploadPo">
                  <v-card>
                    <app-upload-po
                      :quotationId="quotation.id"
                      @closeUploader="completeUpload"></app-upload-po>
                    <v-card-actions>
                      <v-btn text @click="uploadPo = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <div v-else>
                <a
                  :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${quotation.purchase_order_uri}`"
                  target="_blank">
                  <v-btn small>View Purchase Order</v-btn>
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-10 col-sm-6 col-md-4 mt-2 mb-10">
              <v-btn x-large @click="saveQuotation">
                Save Changes
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import Directions from '@/components/googlemaps/Directions.vue';
  import UploadPo from '@/components/Quotations/UploadPO.vue';
  import axios from '../../axios';
  import { format, parseISO } from 'date-fns';
  import { VueEditor } from "vue2-editor";
  
  export default {
    name: 'LiveJobsUpdate',
    components: {
      appDirections: Directions,
      appUploadPo: UploadPo,
      VueEditor,
    },
    data() {
      return {
        changeContact: false,
        addNewContact: false,
        customToolbar: [
          ["bold", "italic", "underline", ],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
        newContact: {},
        searchTerm: '',
        uploadPo: false,
        customer: {},
        foundCustomers: [],
        quotation: {
          customer: 0,
          date: null,
          drawing_ref: '',  
          site: '',
          details: '',
          sectorSeven: 0,
          department_id: 0,
          distance: '',
          drive_time: '',
          postCode: '',
          purchase_order_uri: '',
        },
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        prettyDate: format(parseISO((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), 'EEEE, MMMM do yyyy'),
        departments: [
          { value: 1, text: 'Paint' },
          { value: 2, text: 'Thermo' },
          { value: 3, text: 'Highways' },
        ],
        validities: [
          '10 days',
          '30 days',
          '60 days',
          '90 days',
          '180 days',
        ],
        confidences: [
          { value: 1, text: 'Low' },
          { value: 2, text: 'Medium' },
          { value: 3, text: 'High' },
          { value: 4, text: 'Certainty' },
        ],
        contacts: [],
        surfaceTypes: [],
        siteTypes: [],
        workTypes: [],
      };
    },
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
    },
    watch: {
      date() {
        this.prettyDate = format(parseISO(this.date), 'EEEE, MMMM do yyyy');
        this.quotation.date = this.date;
      },
    },
    methods: {
      cancelNewContact() {
        this.addNewContact = false;
        this.newContact = null;
      },
      saveNewContact() {
        const postData = {};
        postData.customerId = this.quotation.customer.id;
        postData.contact = this.newContact;
        axios.post(`/contacts/addFromQuote.json?token=${this.token}`, postData)
          .then((response) => {
            this.quotation.siteContactId = response.data.contact.id;
            this.quotation.siteContact = response.data.contact.full_name;
            this.quotation.siteTel = response.data.contact.mobile;
            this.quotation.siteEmail = response.data.contact.email;
            const cont = {};
            cont.id = response.data.contact.id;
            cont.name = response.data.contact.full_name;
            this.contacts.push(cont)
            this.addNewContact = false;
            this.newContact = null;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      completeUpload(uri) {
        this.quotation.purchase_order_uri = uri;
      },
      populateDistances(value) {
        this.quotation.distance = value.distance;
        this.quotation.drive_time = value.duration;
      },
      getSurfaceTypes() {
        axios.get(`/surfaceTypes/getAll.json?token=${this.token}`)
          .then((response) => {
            this.surfaceTypes = response.data;
          });
      },
      getSiteTypes() {
        axios.get(`/siteTypes/getAll.json?token=${this.token}`)
          .then((response) => {
            this.siteTypes = response.data;
          });
      },
      getWorkTypes() {
        axios.get(`/workTypes/getAll.json?token=${this.token}`)
          .then((response) => {
            this.workTypes = response.data;
          });
      },
      saveQuotation() {
        const postData = {};
        postData.quotation = this.quotation;
        postData.date = this.date;
        axios.post(`/quotations/update.json?token=${this.token}&user=${this.userid}`, postData)
          .then(() => {
            this.$router.push(`/quotations/view/${this.quotation.id}`);
          }); 
      },
      lookupCustomers() {
        if (this.searchTerm.length > 2) {
          const postData = { searchTerm: this.searchTerm };
          axios.post(`/customers/lookup.json?token=${this.token}`, postData)
          .then((response) => {
            this.foundCustomers = response.data;
            this.customer = this.foundCustomers[0];
          });
        }
      },
      checkCustomer() {
        if (this.$route.params.customer) {
          const customerId = this.$route.params.customer;
          axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
          .then((response) => {
            this.quotation.customer = response.data;
          });
        }
      },
      populateCustomer() {
        const postData = { customer: this.customer };
        axios.post(`/customers/getFromName.json?token=${this.token}`, postData)
          .then((response) => {
            this.foundCustomers = [];
            this.customer = '';
            this.quotation.customer = response.data;
            this.contacts = response.data.contacts;
            this.getJobRef();
          });
      },
      getJobRef() {
        axios.get(`/quotations/getJobRef.json?token=${this.token}`)
        .then((response) => {
          this.quotation.jobRef = response.data;
        });
      },
      resetDate() {
        this.prettyDate = null;
        this.date = null;
        this.quotation.date = null;
      },
      getQuotation() {
        axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
        .then((response) => {
          this.quotation = response.data;
          this.foundCustomers.push(this.quotation.customer);
          this.getContacts(this.quotation.customerId);
        });
      },
      getContacts(customerId) {
        axios.get(`/contacts/getByCustomerId/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.contacts = response.data;
        });
      },
    },
    mounted() {
      this.getQuotation();
      this.getSurfaceTypes();
      this.getSiteTypes();
      this.getWorkTypes();
      this.checkCustomer();
    }
  }
  </script>
  